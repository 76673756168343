#quickList{
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    min-height: 50px;
    margin: 20px 0 10px;
    padding: 0;
    &.open{
        flex: 1 1 auto;
    }
    &.isHalfTop{
        top: 45%
    }
    .add .ip-container{
        opacity: 0.7;
    }
    .custom-bubble{
        border: solid 1px var(--panel-text-shade);
        border-radius: 20px;
        margin-left: 8px;
        padding: 0 4px 0 8px;
        height: 17px;
        line-height: 17px;
        font-size: @fontSize12;
        display: flex;
        .chevron{
            display: block;
            color: @brightYellow;
            width: 14px;
            height: 17px;
            border-radius: 50%;
            margin: 0px 0 1px 5px;
            svg {
                width: 12px;
                height: 17px;
                path{
                    fill: var(--panel-text-shade);
                }
            }
            &.up{
                padding-left: 2px;
                margin-top: 0;
                transform: rotate(180deg);
            }
        }
        &.active{
            color: white;
            border-color: @brightYellow;
            svg path{ fill: rgba(255,255,255,0.9);}
        }
    }
    .ip-container{
        width: 100%;
        list-style: none;
        margin-top: 5px;
        a {
            color: hsla(0,0%,100%,.6);
            display: block;
            font-size: 13px;
            text-decoration: none;
            text-overflow: ellipsis;
            width: 90%;
            white-space: nowrap;
            overflow: hidden;
            &.active {
                color: @brightYellow;
            }
        }
        li{
            font-size: @fontSizeStandard;
            display: flex;
            text-align: left;
            color: rgba(155,158,174,.3);
            font-weight: 300;
            a{
                margin-bottom: 5px;
                line-height: 20px;
            }
            a:hover{
                cursor: pointer;
                color: @brightYellow;
            }
            &.cat-li{
                display: flex;
                flex-direction: column;
                .section {
                    display: flex;
                    justify-content: space-between;
                    color: var(--panel-text);
                    margin-bottom: 5px;
                    i{padding: 0;margin: 0;}
                    .fa-list-ul{
                        color: var(--panel-text-shade);
                        margin-right: 8px;
                        margin-top: 2px;
                        font-size: @fontSize13;
                    }
                    .title{
                        flex-grow: 1;
                        font-weight: 300;
                        color: var(--panel-text);
                        font-size: @fontSize13;
                        text-align: left;
                        svg{
                            width: 10px;
                            height: 10px;
                            margin-bottom: 3px;
                        }
                    }
                    .right-bubble{
                        border: solid 1px var(--panel-text-shade);
                        border-radius: 20px;
                        margin: 0 8px;
                        padding: 0 4px 0 8px;
                        height: 17px;
                        font-size: @fontSize12;
                        .fa-chevron-down,.fa-chevron-up{
                            padding-top: 3px;
                            width: 13px;
                            margin: 0 2px 0 5px;
                            .lightIcon(1px, @sidePanelColor);
                        }
                    }
                }
            }
        }
        svg, rect {
            width: 10px;
            height: 10px;
        }
        svg {
            margin: 5px 5px 0 5px;
        }
        ul{
            padding: 0;
            margin-bottom: 5px;
            list-style: none;
        }
        .catName{
            text-transform: uppercase;
            color: @textColor;
            padding-left: 10px;
            float: left;
        }
        .noData{
            text-transform: uppercase;
            color: red;
            padding-left: 10px;
        }
        .fa-minus{
            display: inline;
            color: red;
            padding: 2px;
            margin: 0;
            width: 20px;
            border: solid 1px transparent;
            margin-top: 3px;
            height: 6px;
            text-align: center;
            &:before{
                line-height: 7px;
                display: block;
            }
            &:hover{
                border: solid 1px red;
                border-radius: 3px;
                color: red;
            }
        }
    }
    .content{
        display: none;
    }
    .controls{
        width: 100%;
        display: flex;
        padding: 10px 30px 15px;
        box-sizing: border-box;
        .position-trigger{display: none;}
        .trigger{
            font-weight: 300;
            color: #9b9eae;
            font-size: 13px;
            width: 100%;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            .chevron{
                display: block;
                color: @brightYellow;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                margin: 1px 0 2px 5px;
                svg {
                    width: 12px;
                    height: 14px;
                    padding-top: 1px;
                    path{
                        fill: var(--panel-text-shade);
                    }
                }
                &.up{
                    transform: rotate(180deg);
                    svg {
                        padding-top: 0px;
                    }
                }
            }
            &:hover{
                cursor: pointer;
                span{color: @brightYellow;}
                svg path{fill: @brightYellow;}
            }
        }
        a{
            display: block;
            font-size: 15px;
            &:not(.trigger):hover{
                cursor: pointer;
                color: white;
            }
        }
        .quick__delete-all{
            display: flex;
            justify-content: center;
            width: 30px;
            color: red;
            border: 1px solid var(--panel-text-shade);
            border-radius: 10px;
            padding: 1px 4px;
            margin-left: 10px;
            text-align: center;
            i{
                pointer-events: none;
                margin: 0;
            }
            &:hover{
                border-color: red;
                i{color:red;}

            }
        }
    }
    &.open {
        .controls {
            min-height: 45px;
            height: auto;
            flex-direction: row;
            justify-content: center;
            box-sizing: border-box;
            .position-trigger{
                display: block;
                width: 25px;
                font-size: 17px;
                color: var(--panel-text);
                &:hover{
                    color: @brightYellow;
                }
            }
        }
        .content {
            display: flex;
            padding: 0 30px 10px 30px;
            box-sizing: border-box;
            width: 100%;
            border-bottom: 1px solid #373b51;
            overflow-y: auto;
            .dropWrap {
                text-align: center;
                &.isOver {
                    display: block;
                }
            }
        }
    }
    &.isOver{
        border: solid 1px @brightGreen;
    }
}

#search{
    .move-to-quicklist, .close-search{
        display: block;
        width: fit-content;
        height: 30px;
        padding: 0 15px;
        margin: 5px 0 15px 0;
        text-align: center;
        line-height: 30px;
        background: transparent;
        box-shadow: 0 1px 3px 0 rgba(0,0,0,0.10);
        border-radius: 21px;
        border: solid 1px rgba(255,255,255,0.3);
        font-size: 16px;
        text-transform: uppercase;
        color: rgba(255,255,255,0.7);
        letter-spacing: 0;
        float: right;
        &:hover{
            cursor: pointer;
            color: #ADCA45;
        }
        &:focus{outline: none;}
    }
    @media(max-width: 850px){
    .move-to-quicklist{display: none};
    }
}

.quick__description{
    border-radius: 5px;
    background: rgba(255,255,255,0.1);
    padding: 10px 10px;
}