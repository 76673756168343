.content-panel{
    .header,
    .control-panel-wrap,
    .graph-inner-wrap,
    .as-info-panel,
    .traceroute-legend .title-head{
        background: var(--panel-background);
        width: 95%;
        border-radius: 3px;
        margin: 0 auto;
        box-shadow: var(--panel-shadow);
        box-sizing: border-box;
    }

    .control-panel-wrap{
        background: transparent;
        box-shadow: none;
    }

    .header{
        padding: 5px 0;
        margin-bottom: 20px;
    }

    .traceroute-legend{
        width: 95%;
        margin: 0 auto;
        margin-top: 20px;
        .title-head{
            display: flex;
            flex-direction: column;
            width: 100%;
            margin: 0 auto;
            padding: 20px 0;
        }
    }
}