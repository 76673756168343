input[type=range] {
    -webkit-appearance: none;
    width: 100%;
    margin: 0 10px;
    background: transparent;
}
input[type=range]:focus {
    outline: none;
}
input[type=range]::-webkit-slider-runnable-track{
    width: 100%;
    height: 6px;
    cursor: pointer;
    box-shadow: 0px 0px 1px #2b2e41, 0px 0px 0px #353950;
    background: #2b2e41;
    border-radius: 10px;
    border: 0.1px solid rgba(43, 46, 65, 0);
}
input[type=range]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
    border: 5px solid #badc4f;
    height: 18px;
    width: 18px;
    border-radius: 18px;
    margin-top: -6px;
    background: #ffffff;
    cursor: pointer;
    -webkit-appearance: none;
}
input[type=range]:focus::-webkit-slider-runnable-track {
    background: #353950;
}
input[type=range]::-moz-range-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    box-shadow: 0px 0px 1px #2b2e41, 0px 0px 0px #353950;
    background: #2b2e41;
    border-radius: 10px;
    border: 0.1px solid rgba(43, 46, 65, 0);
}
input[type=range]::-moz-range-thumb {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
    border: 5px solid #badc4f;
    height: 10px;
    width: 10px;
    border-radius: 18px;
    margin-top: -6px;
    background: #ffffff;
    cursor: pointer;
}
input[type=range]::-ms-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
}
input[type=range]::-ms-fill-lower {
    background: #212332;
    border: 0.1px solid rgba(43, 46, 65, 0);
    border-radius: 20px;
    box-shadow: 0px 0px 1px #2b2e41, 0px 0px 0px #353950;
}
input[type=range]::-ms-fill-upper {
    background: #2b2e41;
    border: 0.1px solid rgba(43, 46, 65, 0);
    border-radius: 20px;
    box-shadow: 0px 0px 1px #2b2e41, 0px 0px 0px #353950;
}
input[type=range]::-ms-thumb {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
    border: 5px solid #badc4f;
    height: 18px;
    width: 18px;
    border-radius: 18px;
    margin-top: -6px;
    background: #ffffff;
    cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
    background: #2b2e41;
}
input[type=range]:focus::-ms-fill-upper {
    background: #353950;
}