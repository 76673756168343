.probeAction(){
    &:hover{
        border: solid 1px var(--header-hover);
        color: var(--header-hover);
        span, i, a{
            color: var(--header-hover);
            cursor: pointer;
        }
    }
    &:focus, &.active{
        background: var(--header-active);
        color: var(--header-active-text);
        span{
            color: var(--header-active-text);
        }
    }
}

.linkAction(){
    &:focus, &.active{
        text-decoration: none;
    }
    &:hover{
        opacity: 1;
        color: var(--link);
        cursor: pointer;
        text-decoration: underline;
    }
}

.btnAction(){
    background: @brightYellow;
    color: white;
    border: none;
    &:hover{
        background: #BDD660;
        cursor: pointer;
    }
    &:focus, &.active{
        background: #8DA632;
        text-decoration: none;
        outline: none;
    }
}

.styleGenericInput() {
    width: 100%;
    background: white;
    border: 1px #C9CBD5 solid;
    border-radius: 3px;
    margin-bottom: 20px;
    padding: 0 30px;
    height: 40px;
    color: #43485E;
    font-size: 17px;
    font-family: @font;
    .borderBoxIt();
    box-shadow: 0px 0px 2px var(--panel-text-shade);
    text-align: left;
    &:focus {
        outline: none;
    }
}