/* BUTTON */
.btn {
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    text-decoration: none;
    span {
        position: relative;
        display: block;
        padding: 0 20px;
        line-height: 50px;
        background: @blue;
        color: #fefefe;
        border-radius: 3px;
        border-top: 2px solid transparent;
        text-decoration: none;
        text-transform: uppercase;
        box-sizing: border-box;
        @media (max-width: @mobileMax) {
            font-size: 12px;
        }
    }
    &:hover {
        background: none;
        span {
            background: @blueHover;
        }
    }
    &:active {
        span {
            border-color: @blueClick;
        }
    }
    &:disabled {
        cursor: default;
        span {
            background: #d9d9d9;
        }
        &:active {
            span {
                border-color: transparent;
            }
        }
    }
}

.btn-full {
    width: 100%;
}

.btn-small {
    span {
        font-size: 12px;
        line-height: 26px;
        padding-top: 1px;
        padding-bottom: 2px;
        @media (min-width: @full) {
            font-size: 13px;
        }
    }
}

.btn-red {
    span {
        background: @red;
    }
    &:hover:not([disabled]) {
        span {
            background: @redHover;
        }
    }
    &:active {
        span {
            border-color: @redClick;
        }
    }
}

.btn-white {
    span {
        background: #fff;
        color: #2285c0;
    }
    &:hover {
        span {
            background: #ddd;
        }
    }
    &:active {
        span {
            border-color: #ccc;
        }
    }
}

.blue-atag-button {
    border: solid 1px #168ad1;
    padding: 5px 15px;
    border-radius: 2px;
    text-decoration: none;
}