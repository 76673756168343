// UTILITIES

//zarovnani elementu v main panel dle IP
@zarovnaniElemMP: 30px;
@graphLeftMargin: 50px;
// Paths
@img: '../img/';
@ieRGBA: '../img/rgba.php';
@bg: '../img/bg/all.png';

// Breakpoints
@mobileLS: 480px;
@mobileMax: 699px;
@tablet: 700px;
@tabletMax: 999px;
@desktop: 1000px;
@full: 1600px;

//------------------------------------------------------------

.rounded-corners (@radius:10px) {
    -webkit-border-radius: @radius;
    -moz-border-radius: @radius;
    border-radius: @radius;
}

.btnConfirm(@width:26%, @backgroundColorHover:@colorLink, @colorHover:@white){
    width: @width;
    .rounded-corners(20px);
    &:hover, &:focus {
        background: @backgroundColorHover/*lighten(saturate(@mainBackground,50%), 20%)*/;
        color: @colorHover;
        cursor: pointer;
        outline: none;

    }
}

.columns (@count:3, @gap:20px, @width:25%){
    -moz-column-count: @count;
    -moz-column-gap: @gap;
    -moz-column-width: @width;
    -webkit-column-count: @count;
    -webkit-column-gap: @gap;
    -webkit-column-width: @width;
    column-count: @count;
    column-gap: @gap;
    column-width:@width;
}

.calculated-width(@value, @subtract) {
    width: -webkit-calc(@value - @subtract);
    width:    -moz-calc(@value - @subtract);
    width:         calc(@value - @subtract);
}

::-webkit-scrollbar{
    width: 6px;  /* for vertical scrollbars */
    height: 6px; /* for horizontal scrollbars */
}
::-webkit-scrollbar-track{background: rgba(0, 0, 0, 0.1);}
::-webkit-scrollbar-thumb{background: rgba(173,198,92, 0.5);}
::-webkit-scrollbar-corner {background: rgba(0,0,0,0);}


.empty-config {
    flex-direction: column;
    .modal-wrap{
        justify-content: center;
    }
    .tab-content{
        width: 80%;
        margin: 0 auto;
    }
    .top-head-line{
        display: flex;
        justify-content: center;
        padding: 0 50px;
        margin-bottom: 20px;
        .page-title {
            display: block;
            color: #fff;
            font-size: 15px;
            margin: 0 0 25px 0;
        }
    }
    h1 {
        font-size: @fontSizeH1;
        color: white;
        text-align: center;
    }
    .only-option {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0;
        text-align: center;
        li {
            font-size: 30px;
            color: @textColor;
            list-style: none;
            margin-bottom: 15px;
            .fa {
                margin-right: 15px;
            }
            &:hover {
                color: white;
            }
        }
    }
}

html, body, #routeContent, #father, #mother{
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
    font-family: @font;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.highcharts-axis-labels {
    &.highcharts-xaxis-labels,  &.highcharts-yaxis-labels{
        text{
            font-family: @font !important;
        }
    }

}