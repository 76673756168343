.aspath{
    display: flex;
    .title{
        margin-right: 15px;
        letter-spacing: 1px;
        color: var(--title);
        font-weight: 700;
        line-height: 25px;
        font-size: @fontSize12;
    }
    ul{
        list-style: none;
        display: flex;
        padding: 0;
        margin: 0;
        flex-wrap: wrap;
        li{
            color: var(--title);
            font-size: 14px;
            font-weight: bold;
            border-radius: 20px;
            height: 25px;
            line-height: 25px;
            text-align: center;
            padding: 0px 10px;
            margin: 0 5px;
            margin-top: -1px;
            margin-bottom: 10px;
        }
    }
}