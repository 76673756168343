.panel__no-as{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    margin: 50px auto;
    .as-info-panel{
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        spam{color: white;}
        p{color: rgba(255,255,255,0.7);}
        a{
            color: white;
            font-weight: bold;
            margin-top: 15px;
        }
    }
}