.trace-table{
    display: block;
    padding: 0;
    margin: 0 auto;
    position: relative;
    table{
        font-weight: 400;
        width: 100%;
        position: relative;
        margin: 0 auto;
        border: none;
        border-collapse: collapse;
        th {
            letter-spacing: 1px;
            font-weight: 300;
            color: var(--title);
            font-size: 13px;
            text-align: left;
            padding: 10px 0;
            &:first-child {
                padding-right: 10px;
            }
        }
        tr{
            height: 25px;
            &.light{td{background: var(--table-row-second)}}
            &.dark{td{background: transparent}}
            &:hover{
                cursor: pointer;
                td{
                    color: var(--title);
                }
            }
        }
        td {
            padding: 5px 2px;
            color: var(--panel-text);
            font-weight: 300;
            font-size: 13px;
            &.hop-order {
                width: 10px;
                padding: 3px 10px;
            }
            &.first{border-radius: 5px 0 0 5px;}
            &.last{border-radius: 0 5px 5px 0;}
            &.hop-perc {
                display: flex;
                box-sizing: border-box;
                height: 30px;
            }
            &.hop-time {width: 80px;}
            &.hop-id {width: 150px;}
            &.hop-host{white-space: nowrap;}
        }
        div.bar {
            width:80px;
            height: 17px;
            margin: 0 10px 2px 0px;
            display: flex;
            svg{
                height: 100%;
                width: 8px;
                rect{
                    rx: 2px;
                }
            }
        }
    }
    @media(max-width: 1350px){
        .text-percentage{
            display: none;
        }
    }
}