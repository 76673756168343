#dashboard {
    tr{
        &:hover:not(.no-hover){
            td{
                color: @white;
                cursor: pointer;
            }
        }
    }
    font-size: @fontSizeStandard;
    h1 {
        color: @white;
        font-size: @fontSizeH1;
        font-weight: normal;
        margin: 50px 0 30px;
    }
    h3 {
        font-size: @fontSizeH3;
        font-weight: normal;
    }
    .dashboard-header {
        .sk-fading-circle{
            display: inline-block;
            margin: 0;
            margin-left: 20px;
            width: 25px;
            height: 25px;
            position: relative;
        }
        display: block;
        width: 100%;
        h1{
            margin-top: 0;
            display: inline-block;
        }
    }
    .control-header{
        display: block;
        position: relative;
        width: 100%;
        height: 80px;
        margin-top: 10px;
        margin-bottom: 15px;
        max-width: 1500px;
        margin: auto;
        .control-title{
            display: block;
            color: #486F97;
            font-size: 18px;
        }
        .category-dropdown{
            width: 300px;
            #categoryInput{
                width: 100%;
            }
        }
        .change {
            display: inline-block;
            float: left;
            width: 150px;
            margin-top: 13px;
            border: solid 1px rgba(72, 111, 151, 0.3);
            input {
                background: @mainBackground;
                border: none;
                color: @lightGrey;
                width: 60px;
                height: 33px;
                text-align: center;
                font-size: @fontSizeStandard;
            }
            span{
                color: @lightGrey;
                margin-left: 5px;
            }
        }
        .time-block{
            display: block;
            width: 200px;
            height: 100%;
            margin-top: 10px;
            input.datepicker-trigger{
                width: 100px;
            }
        }
        .select-wrap{
            border: solid 1px rgba(72, 111, 151, 0.3);
            display: inline-block;
            float: left;
            margin-top: 13px;
            margin-right: 10px;
            .select-box{
                width: 150px;
            }
            &.time-input {
                .select-box{
                    width: 100px;
                }
            }
            &.multiple-input{
                float: left;
                width: 40%;
                .select-box{
                    width: 100%;
                }
                .select2-selection--multiple{
                    border: none;
                    border-bottom: solid 2px @lightGrey;
                }
                .select2-selection__choice{
                    background-color: transparent;
                }
            }
        }
        .refresh{
            float: right;
            color: white;
            font-size: 20px;
            margin-top: 20px;
            margin-right: 15px;
            &:hover{
                cursor: pointer;
            }

        }
        .select2-selection__arrow b{
            border-color: rgba(255, 255, 255, 0.7) transparent;
        }
    }
    .dashboard-body {
        margin-left: 20px;
        .title {
            color: @blueDark;
            text-transform: uppercase;
            padding: 10px 0 15px;
        }
        table {
            width: 95%;
            border-collapse: collapse;
        }
    }
    .dashboard-section{
        margin-top: 30px;
    }
    .threshold-line{
        width: 90%;
        display: flex;
        flex-direction: column;
        margin: 15px 0 15px 0;
        .line{
            display: block;
            &.left-pad{
                padding-left: 50px;
            }
        }
        .desc{
            margin-left: 50px;
            &.left-pad-15{
                margin-left: 15px;
            }
        }
        span{
            color: gray;
            opacity: 0.7;
            text-transform: uppercase;
        }
    }
    .dashboard-table {
        max-width: 1500px;
        position: relative;
        margin: auto;
        .under-section{
            max-width: 95%;
            margin: 0 0 50px 50px;
        }
        .nodata{
            color: white;
            font-size: 20px;
            display: block;
            margin: 40px 0 0 40px;
        }
        th {
            color: @darkGrey;
            text-transform: uppercase;
            font-size: @fontSize12;
            font-weight: normal;
            text-align: left;
            padding: 10px 0 10px 20px;
            &:first-child {
                padding-left: 0;
            }
            &:hover{
                color: white;
                cursor: pointer;
            }
            &.active{
                color: @blue
            }
        }
        tr {
            border-bottom: 1px solid @midGrey;
        }
        td {
            color: @lightGrey;
            padding: 2px 0 2px 5px;
            &.graph{
                width: 100%;
                height: 200px;
            }
            &.ip-copy {
                width: 40px;
            }
            &.time-range {
                width: 300px;
            }
            &.incident, &.incident-value {
                text-align: center;
                width: 160px;
                span {
                    display: inline;
                }
            }
            &.incident-pl{
                width: 180px;
            }
            &.link{
                width: 30px;
                text-align: center;
                a{
                    color: white;
                    &:hover{
                        cursor: pointer;
                        color: @blueBright;
                    }
                }
            }
            //div.bar {
            //    display: inline-block;
            //    height: 17px;
            //    vertical-align: middle;
            //    margin: 0 5px 2px 5px;
            //    width:80px;
            //    background: @midGrey url('/less/img/bar.png') repeat-x left top;
            //}
            //div.bar-blue {
            //    height:17px;
            //    background: @blue url('/less/img/bar.png') repeat-x left top;
            //}
            //div.bar-red {
            //    height:17px;
            //    background: @redDark url('/less/img/bar.png') repeat-x left top;
            //}
            //div.bar-violet {
            //    height:17px;
            //    background: @violet url('/less/img/bar.png') repeat-x left top;
            //}
            .ip-copy-btn{
                color:@lightGrey;
                .rounded-corners(3px);
                border:1px solid @lightWhite;
                padding: 5px 7px;
                font-size: @fontSizeStandard;
                &:hover{
                    border-color: @white;
                    color:@white;
                }
            }
            &.inc-count{
                text-align: center;
                width: 50px;
            }
        }
    }
}