.tab-slider(@tabs-width, @text, @activeColor, @border-bottom-color, @tab-slide-height:30px){
    .tab-slider{
        &--nav{
            width: 100%;
            margin: 20px 0 0;
        }
        &--tabs{
            display: flex;
            flex-direction: row;
            justify-content: left;
            width: @tabs-width;
            height: @tab-slide-height;
            margin: 0 auto;
            padding: 0;
            border-bottom: solid 1px @border-bottom-color;
            box-sizing: border-box;
            li{
                list-style: none;
            }
        }
        &--trigger{
            padding: 10px;
            border-radius: 3px;
            width: fit-content;
            padding: 0 10px 20px 5px;
            color: @text;
            box-sizing: border-box;
            text-align: center;
            background: transparent;
            text-transform: uppercase;
            height: @tab-slide-height;
            &:first-of-type{
                border-radius: 0 3px 0 0;
            }
            &:last-of-type{
                border-radius: 3px 0 0 0;
            }
            &.active{
                color: @activeColor;
                border-bottom: solid 1px @activeColor;
            }
            &:hover{
                cursor: pointer;
                color: @activeColor;
            }
        }
    }
}