@fontSizeH1: 35px;
@fontSizeH2: 25px;
@fontSizeH3: 120%;
@fontSizeStandard: 15px;
@fontSizeSmall: 14px;
@fontSize12: 12px;
@fontSize13: 13px;

@font: 'Lato',sans-serif;
@fontTitles: @font;
@fontSize: 15px;
@lineHeight: 20px;
@sidePanelSection: 16px;