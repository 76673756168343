@sideText: #C9CBD6;
@sideBrightText: rgba(155,158,174,.3);
@sideTextHover: @brightYellow;
@sideTextActive: #FFFFFF;
@sidePanelColor: #24263A;


.side-panel {
    ::-webkit-scrollbar-track{background: rgba(0, 0, 0, 0);}
    .servers{
        text-align: center;
    }
    font-size: @fontSize;
    display: flex;
    flex-direction: column;
    z-index: 2;
    color: @sideText;
    background: rgba(25,27,46,1);
    box-sizing: border-box;
    a {
        color: @sideText;
        display: block;
        font-size: @fontSize13;
        text-decoration: none;
        text-overflow: ellipsis;
        width: 90%;
        white-space: nowrap;
        overflow: hidden;
        &.active {
            color: white;
        }
    }
    .home {
        display: inline-block;
        width: 90%;
        margin: 10px;
    }
    ul {
        display: block;
        padding: 0;
        list-style: none;
        margin-bottom: 20px;
        .fa {
            padding: 10px 0;
            margin: 10px 0 0;
            span {
                margin-left: 7px;
            }
        }
        .fa-chevron-down::before, .fa-chevron-up::before {
            margin-right: 7px;
        }
        #ip-container {
            margin-top: 10px;
            margin-bottom: 10px;
            li {
                margin-bottom: 10px;
                &.active a{
                    color: @sideTextActive;
                }
            }
            ul{
                margin-top: 5px;
            }
        }
    }
    .add-ip, .add-category{
        &:hover {
            color: @sideTextHover;
            cursor: pointer;
        }
    }
    #sidebar {
        width: 100%;
        font-size: @fontSizeStandard;
        max-height: calc(~'100% - 50px');
        position: relative;
        margin-top: 30px;
        margin-bottom: 30px;
        overflow-x: hidden;
        overflow-y: scroll;
        padding: 0 30px;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(255,255,255,0.1);
        padding-bottom: 30px;
        background: transparent;
        .top-ul{
            padding: 0;
            width: 100%;
            margin: 0 auto;
            border: none;
            margin-top: 0px;
            overflow-x: hidden;
            li{
                width: 100%;
                margin: 0 auto;
                .first-line{display: flex;}
            }
            &.open{
                display: block;
            }
            &.close{
                display: none;
            }
        }
        a {
            width: 100%
        }

        #ip-container {
            a {
                position: relative;
                margin: 0 auto;
                width: 100%;
                line-height: 140%;
                &.active {
                    color: @sideTextActive;
                }
            }
            svg, rect {
                width: 10px;
                height: 10px;
            }
            svg {
                margin-right: 15px;
            }
        }
        .section {
            display: flex;
            justify-content: space-between;
            margin: 0 auto;
            color: @sideText;
            i{padding: 0;margin: 0;}
            .fa-list-ul{
                color: @sideBrightText;
                margin-right: 8px;
                margin-top: 2px;
                font-size: @fontSize13;
            }
            .title{
                flex-grow: 1;
                font-weight: 300;
                color: @sideText;
                font-size: @fontSize13;
                overflow-x: hidden;
                svg{
                    width: 10px;
                    height: 10px;
                    margin-bottom: 3px;
                }
            }
            .right-bubble{
                border: solid 1px @sideBrightText;
                border-radius: 20px;
                margin-left: 8px;
                padding: 0 4px 0 8px;
                height: 17px;
                line-height: 17px;
                font-size: @fontSize12;
                display: flex;
                .chevron{
                    display: block;
                    color: @sideTextActive;
                    width: 14px;
                    height: 17px;
                    border-radius: 50%;
                    margin: 0px 0 1px 5px;
                    svg {
                        width: 12px;
                        height: 17px;
                        path{
                            fill: @sideBrightText;
                        }
                    }
                    &.up{
                        padding-left: 2px;
                        margin-top: 0;
                        transform: rotate(180deg);
                    }
                }
                &.active{
                    color: white;
                    border-color: @sideTextHover;
                    svg path{ fill: @sideTextActive;}
                }
            }
            &:hover{
                color: @sideTextHover;
                .title, .fa, .right-bubble{
                    color: @sideTextHover;
                    border-color: @sideTextHover;
                }
            }
        }
        .category-content{
            border-left: 1px solid rgba(155,158,174,0.1);
            margin-bottom: 10px;
            margin-left: 5px;
            li{
                margin: 0 0 0 auto;
                width: 95%;
            }
        }
        .ip-li{
            display: flex;
            color: @sideBrightText;
            font-weight: 300;
            margin-bottom: 10px;
            a{
                margin-bottom: 5px;
                line-height: 20px;
            }
            svg{
                margin: 4px 3px 0 1px;
                height: 11px;
                width: 11px;
            }
            :hover{
                color: @sideTextHover;
                .category-section-name .title.slide-text {
                    transition: all 5s ease;
                    transform: translateX(calc(~'-90%'));
                }
            }
        }
    }
    .tab-slider(80%, @sideText, @brightYellow, #373B51);
    @media (max-width: 850px) {
        display: none;
        position: relative;
        margin: 0 auto;
        min-width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        color: @lightGrey;
        a {
            color: @lightGrey;
            text-decoration: none;
        }

        #logo, #search, #login-info {
            display: none;
        }

        #sidebar {
            width: 90%;
            li {
                margin-top: 15px;
                border-bottom: solid 1px dimgray;
                padding-bottom: 20px;
            }
        }
    }
    &.hidden{
        display: none;
    }
}

.settings{
    .side-panel{
        ul.options{
            padding-left: 35px;
            margin-top: 55px;
            li {
                display: flex;
                margin-bottom: 30px;
                i.fa{
                    padding: 0;
                    margin: 0 15px 0 0;
                }
                a{
                    font-size: 12px;
                    letter-spacing: 0.46px;
                    color: @sideText;
                }
                &.active{
                    a{
                        color: @sideTextHover;
                    }
                }
                &:hover{
                    a{
                        color: @sideTextHover;
                    }
                }
            }
        }
    }
}

.home{
    text-align: center;
    line-height: 50px;
    height: 50px;
    #logo {
        display: block;
        margin: 0 auto;
        width: 65px;
    }
    #text-logo{
        display: block;
        margin-top: 10px;
        color: var(--logo-color);
        font-weight: bold;
        font-size: 20px;
        span{
            font-weight: 100;
            margin-left: 2px;
            letter-spacing: 2px;
        }
    }
}