@white:#fff;
@lightWhite: rgba(255,255,255,0.3);
@lightWhiteActive: rgba(255,255,255,0.7);
@dimGrey: #1C282D;
@lightGrey: #BBBDBE;
@darkGrey: #777777;
@midGrey:#1F2731;

@dimBlue : #2A3746;
@textColor: #486F97;
@brightBlue: #0451A0;
@blue: #0451A0;
@mainBackground: #2B2E42;
@sidePanelColor: #24263A;
@brightYellow: #FFFE38;
@brightGreen: #19e880;
@brightRed: #FF0202;

@color: #04151f;
@colorLink: @blue;
@colorHover: #fff;

@blueTabActive: #0074DB;
@blueHover: #1b9be9;
@blueHoverLight: #557BA5;
@blueClick: #1285ca;
@blueDark: #0a7abf;
@blueLight: #afdce7;
@blueLighter: #74b6e9;
@blueBright: #0A89DF;
@red: #e64c3c;
@redHover: #f2321e;
@redClick: #d82816;
@redLight: #f5998f;
@redPink: #ef7164;
@redDark: #d20014;
@violet: #ae00ae;

@yellow: #f8d700;
@yellowLight: #f5e684;
@yellowDark: #ecd25e;

@green: #32cd2e;
@greenLight: #88f086;

@modalBackground:#426C9B;
@modalColor:#A9BCD2;
@modalColorHover:#2A435F;
@modalSidePadding: 50px;
@radius:10px;

@topPanelWidthChange: 1219px;

@datepickerHover: #6889AF;
@datepickerActive: #6da5e3;//#9db3cb; //#72ACEC;
@datepickerGrey: #7b98b9; //#5C636A;


// ------------------- DARK THEME DEFAULT
:root{
    --main-content-background: #2B2E42;
    --panel-background: #3F4155;
    --panel-shadow: 0 3px 5px 0 rgba(13,16,34,0.2);
    --panel-text: #C9CBD6;
    --panel-text-shade: rgba(155,158,174,0.3);
    --title: #FFFF;
    --link: @brightYellow;

    // Top Panel
    --top-panel-background: rgba(25,27,46,1);
    --search-focus: #252936;

    // Header Panel
    --header-text: rgba(201,203,214,0.7);
    --header-divide-line: #5A5D71;
    --header-hover: @brightYellow;
    --header-active: #24263A;
    --header-active-text: #FFFFFF;
    --header-matrix-text: var(--header-divide-line);
    --header-matrix-background: #0D1022;
    --probe-trophy: gold;

    --logo-color: #FFFFFF;

    //Graph Panel
    --graph-text: var(--panel-text);

    // Slider
    --slider-dot: #FFF;
    --slider-background: @brightYellow;

    // Trace
    --trace-line-background: #4C4E61;

    //Table
    --table-row-second: rgba(255,255,255,0.15);
}





// ------------------- LIGHT THEME
.theme-light{
    --main-content-background: #F4F5F7;
    --panel-background: #FFFFFF;
    --panel-shadow: 0 1px 5px 0 rgba(34,37,61,0.1);;
    --panel-text: #5A5D71;
    --panel-text-shade: #9B9EAE;
    --title: #22253D;
    --link: #96B233;

    // Top Panel
    --top-panel-background: rgba(255,255,255,1);
    --search-focus: #E8E9EF;
    --logo-color: #000;

    // Header Panel
    --header-text: #5D6072;
    --header-divide-line: #E8E9EF;
    --header-hover: #96B041;
    --header-active: #24263A;
    --header-active-text: #FFF;
    --header-matrix-text: #5A5D71;
    --probe-trophy: goldenrod;
    --header-matrix-background: #FFFFFF;

    // Graph Panel
    --graph-text: var(--panel-text);

    // Slider
    --slider-dot: #FFF;
    --slider-background: #96B233;

    // Trace
    --trace-line-background: rgba(201,203,214, 0.1);

    // Table
    --table-row-second: #E8E9EF;

}



//-------------------- NEW DESIGN COLORS --------------------
@blockBackground: #3F4155;
@headerActiveColor: #24263A;

@topPanelColor: #0D1022;

@brightYellow: #ADC65C;
@midYellow: rgba(173,198,92,0.4);
@darkYellow: #475131;

@grayTextColor: #9B9EAE;
@grayTextColorLight: rgba(155,158,174,0.3);

@mainBackground: #2B2E42;
@contextMenu: #5A5E6F;