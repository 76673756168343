/* lato-regular - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url('../../libs/lato/lato-v14-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Lato Regular'), local('Lato-Regular'),
    url('../../libs/lato/lato-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../libs/lato/lato-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../libs/lato/lato-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../../libs/lato/lato-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../libs/lato/lato-v14-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}