.matrix{
    display: flex;
    width: fit-content;
    flex-grow: 1;
    justify-content: flex-end;
    overflow: hidden;
    .side-action-button{
        margin-top: 6px;
        margin-left: 5px;
    }
    .matrix-center{
        display: flex;
        overflow-x: auto;
    }
    .slave-name{
        padding-right: 15px;
        text-align: right;
    }
    .right-wrap{
        width: 100%;
        padding-bottom: 10px;
        display: flex;
        justify-content: flex-end;
        .side-action-button{
            margin: 0;
        }
    }
    .table-wrap{
        display: flex;
        table{
            border-collapse: collapse;
            border: none;
            display: block;
            height: 100%;
            thead{
                td{
                    color: var(--header-text);
                    font-weight: 300;
                    font-size: 12px;
                    text-align: right;
                    border: none;
                    padding: 0;
                    padding-bottom: 2px;
                    &:first-of-type{
                        padding-right: 15px;
                    }
                }
            }
            tbody{
                th{
                    color: var(--title);
                    font-size: 13px;
                    line-height: 25px;
                }
                .slave-name{
                    color: var(--title);
                }
                tr:hover{
                    cursor: pointer;
                    .slave-name{
                        color: var(--title);
                    }
                }
                svg,rect{
                    width: 20px;
                    height: 6px;
                    &.first{border-radius: 10px 0 0 10px;}
                    &.last{border-radius: 0 10px 10px 0;}
                }
            }
        }
        &.absolute{
            display: flex;
            flex-direction: column;
            position: absolute;
            z-index: 10;
            max-height: 400px;
            background: var(--header-matrix-background);
            box-shadow: var(--panel-shadow);
            padding: 20px;
            border-radius: 3px;
            box-sizing: border-box;
            right: 2.5%;
            table{
                overflow-y: auto;
                .slave-name{
                    color: var(--header-matrix-text);
                }
            }
        }
    }
    .switches{
        display: flex;
        padding-top: 3px;
        margin: 0 20px;
        .switch{
            display: flex;
            margin-right: 25px;
            .switch-slider{
                margin: 1px 10px;
            }
            span{
                font-size: @fontSize13;
                color: var(--panel-text-shade);
                .linkAction();
                &.active{
                    color:@brightYellow;
                }
            }
        }
    }
    .desc{
        color: var(--header-text);
        text-align: center;
    }
}