.search{
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    input{
        border-radius: 3px;
        background: var(--main-content-background);
        height: 30px;
        border: none;
        outline: none;
        box-shadow: none;
        padding: 0 10px;
        width: 80%;
        color: white;
        text-align: center;
        font-size: 18px;
    }
}

.mobileSearch{
    &__wrap{
        input{
            background: var(--panel-background);
        }
        #sidebar--mobile{

        }
    }
    &__list{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        background: var(--main-content-background);
        z-index: 10;
        top: 40px;
        margin: 0;
        padding: 0;
        list-style: none;
        padding-top: 2rem;
        .ip-li{
            display: flex;
            color: @sideBrightText;
            font-weight: 300;
            margin-bottom: 5px;
            padding: 0 10px;
            &__close{
                display: flex;
                color: rgba(155,158,174,.3);
                font-weight: 300;
                margin-bottom: 10px;
                padding: 0 10px;
                justify-content: flex-end;
                a{
                    background: var(--panel-background);
                    padding: 5px 10px;
                    border-radius: 5px;
                    .svg__wrap{
                        margin-right: 5px;
                    }
                    .title {
                        flex-grow: 1;
                        font-weight: 300;
                        color: @sideText;
                        font-size: @fontSize13;
                        overflow-x: hidden;
                        display: flex;
                        align-items: center;
                        padding: 2px 5px 2px 2px;
                        justify-content: flex-start;
                    }
                }
            }
            .first-line{
                background: var(--panel-background);
                border-radius: 5px;
                width: 100%;
                overflow: hidden;
            }
            a{
                margin-bottom: 5px;
                line-height: 20px;
            }
            svg, rect{
                height: 100%;
                width: 100%;
            }
        }
        .section {
            display: flex;
            justify-content: space-between;
            margin: 0 auto;
            color: @sideText;
            white-space: nowrap;
            .title{
                flex-grow: 1;
                font-weight: 300;
                color: @sideText;
                font-size: @fontSize13;
                overflow-x: hidden;
                display: flex;
                align-items: center;
                padding: 2px 5px 2px 2px;
                justify-content: flex-start;
                .svg__wrap{
                    border-radius: 5px;
                    height: 25px;
                    margin: 3px 5px 3px 3px;
                    min-width: 15px;
                    max-width: 15px;
                    svg{
                        overflow: visible;
                    }
                }
                span{
                    overflow: hidden;
                }
            }
        }
    }
}