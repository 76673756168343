.graph-inner-wrap{
    padding: 5px 0;
    .panel{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0 30px;
        .switches{
            display: flex;
            margin: 0 auto;
            .switch{
                display: flex;
                margin-right: 25px;
                .switch-slider{
                    margin: 0px 7px;
                }
                span{
                    font-size: @fontSize13;
                    line-height: 20px;
                    color: var(--graph-text);
                    .linkAction();
                    &.active{
                        color: var(--link);
                    }
                }
                &:last-of-type{
                    margin: 0;
                }
            }
        }
        .range-slider{
            display: flex;
            width: 100px;
            margin-right: 10px;
            span{
                color: var(--link);
                font-size: 17px;
                line-height: 20px;
                .lightIcon(1px, var(--panel-background));
                &:hover{
                    text-decoration: none;
                    cursor: pointer;
                }
            }
            span.big{
                font-size: 35px;
            }
        }
        .controls-wrap{
            display: grid;
            grid-template-columns: 1fr auto 1fr;
            grid-template-rows: 1fr;
            grid-template-areas: 'slider switches share';
            @media(max-width: 600px){
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 1fr 1fr;
                grid-template-areas:
                    'slider share'
                    'switches switches';
            }
            width: 100%;
            .range-slider{
                grid-area: slider;
                justify-self: start;
            }
            .switches{
                grid-area: switches;
                justify-content: center;
            }
            .share-ip{
                grid-area: share;
                width: fit-content;
                justify-self: end;
                width: 100px;
            }
            & > div{
                margin-top: 10px;
                margin-bottom: 10px;
            }

        }
        .share-btn{
            color: var(--link);
            font-size: 16px;
            i{margin-right: 10px;}
            .linkAction();
        }
    }
    .probe-title-wrap{
        margin: 30px 80px;
        width: fit-content;
        color: white;
    }
}

.main-panel{
    &.side-relative{
        @media(max-width: 1000px){
            .modifyInnerGraph();
        }
    }
    &:not(.side-relative){
        @media(max-width: 750px){
            .modifyInnerGraph();
        }
    }
}

.modifyInnerGraph(){
    .graph-inner-wrap .panel{
        .controls-wrap{
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            grid-template-areas:
                'switches switches'
                'slider share';
        }
        .range-slider{
            justify-self: end;
            margin-right: 30px;
        }
        .share-ip{
            justify-self: start;
            margin-left: 30px;
        }
    }
}

p.text-white{
    color: rgba(255,255,255,0.7);
}