@traceColor: RGBA(150, 178, 51, 0.4);
@traceColorActive: #96B233;

.traceroute-legend {
    .title-head {
        &__top{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 5px 30px;
        }
        .legend-line-wrap{
            width: 95%;
            box-sizing: border-box;
            margin: 20px auto;
            border-right: solid 1px var(--title);
            border-left: solid 1px var(--title);
            .legend-line-background{
                background: var(--trace-line-background);
                margin-bottom: 10px;
            }
        }
    }
    .right, .left {
        span {
            margin-right: 15px;
            letter-spacing: 1px;
            font-weight: 500;
        }
    }

    .left a {
        color: var(--link);
        .linkAction();
    }

    .light {
        color: rgba(155, 158, 174, .8);
    }

    span.bold {
        color: var(--title);
        font-weight: bold;
    }
    .fa-info-circle{
        color: var(--link);
    }
    .traceroute-detail {
        display: block;
        width: 100%;
        margin: 30px auto;
        padding: 0 15px;
        box-sizing: border-box;
    }

    .legend-line {
        margin-left: 60px;
        background: var(--trace-line-background);
        height: 30px;
        border-radius: 3px;
        position: relative;
        .trace-record {
            display: inline-block;
            height: 27px;
            position: absolute;
            text-align: center;
            border: solid 1.5px @traceColor;
            z-index: 1;
            margin-top: 0;
            border-radius: 5px;
            &.active {
                border: solid 1.5px @brightYellow;
            }

            &:hover {
                border: solid 1.5px @brightYellow;
            }
        }
    }

    .traces {
        margin-top: 20px;
        overflow-x: auto;
        .trace-data-record {
            margin-top: 20px;
            min-width: 850px;
            .time {
                color: var(--title);
                font-size: 14px;
                font-weight: bold;
            }
        }
    }
}