.as-info-panel{
    display: flex;
    margin-bottom: 20px;
    width: fit-content;
    min-width: 40%;
    padding: 15px 40px;
    justify-content: center;
    text-align: center;
    &--main{
        background: transparent;
        margin-bottom: 20px;
        width: 95%;
        margin: 0 auto;
        display: flex;
        margin-bottom: 20px;
        .as__child{
            background: var(--panel-background);
            border-radius: 3px;
            box-shadow: var(--panel-shadow);
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--title);
            height: 45px;
            padding: 10px 15px;
            font-weight: bold;
            &:first-of-type{
                margin-right: 20px;
            }
            &--icons{
                padding: 0;
                background: transparent;
            }
        }
    }
    span{
        color: var(--title);
        font-size: 18px;
        font-weight: 700;
    }
    &__icon{
        background: transparent;
        height: 100%;
        width: 40px;
        font-size: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:first-of-type{
            border-radius: 3px 0 0 3px;
        }
        &:last-of-type{
            border-radius: 0 3px 3px 0;
        }
        &.active{
            background: var(--panel-background);
            color: var(--panel-text);
        }
        &:hover{
            color: @brightYellow;
        }
    }
}