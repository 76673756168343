.topPanelShadow(){
    box-shadow: inset 0 1px 3px rgba(0,0,0,.3), 0 1px hsla(0,0%,100%,.1);
}

.lightIcon(@px, @color){
    -webkit-text-stroke: @px @color;
}

.noOutline(){
    &:focus{
        outline: none;
    }
}

.lightText(){
    color: var(--panel-text-shade);
    font-weight: 200;
};

.lightAndBrightText(){
    color: var(--panel-text);
    font-weight: 200;
};

.borderBoxIt(){
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
}