.chart-container {
    display: block;
    width: 95%;
    height: 400px;
    margin: 0 auto;
}

.tooltip{
    display: flex;
    flex-direction: column;
    background: RGBA(43, 46, 65, 1.00);
    color: rgba(255,255,255,0.8);
    box-shadow:var(--panel-shadow);
    padding: 10px 20px;
    border-radius: 3px;
    span{
        margin-bottom: 3px;
    }
    .val{
        padding-left: 10px;
        color: var(--link);
    }
}

.probe-graph-wrap.multiple{
    margin-top: 30px;
}
