.time-block-wrap {
    display: flex;
    height: 100%;
    width: fit-content;
    flex-grow: 1;
    justify-content: flex-start;
    margin: 0 20px 15px 0;
    box-sizing: border-box;
    .icon-calendar {
        margin: 9px 10px 0 3px;
        svg path{
            fill: var(--panel-text-shade);
        }
    }
    .fast-dial {
        display: flex;
        padding-top: 3px;
        a {
            font-size: @fontSize13;
            color: var(--header-text);
            border-radius: 20px;
            padding: 5px;
            letter-spacing: 1px;
            height: 15px;
            border: solid 1px transparent;
            margin-right: 2px;
            .probeAction();
            &.active{
                padding: 5px 10px;
            }
        }
    }
    .utc{
        margin-top: 5px;
        padding: 3px 10px;
    }
    .calendar-block {
        margin-left: 5px;
        .side-action-button{
            margin-top: 8px;
        }
        .inner-block {
            height: 100%;
        }
    }
}

.epoch-matrix-block{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 5px 15px 0;
    border-top: solid 1px var(--header-divide-line);
    padding-top: 15px;
}