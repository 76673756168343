.navigator{
    border: none;
    position: relative;
    width: 100%;
    height: 20px;
    margin-top: 7px;
    margin-bottom: 7px;
    font-size: @fontSizeStandard;
    li{
        display: inline-block;
        margin-right: 10px;
        a{
            span{
                padding: 0;
                margin: 0;
            }
            overflow: hidden;
            text-align: center;
            &:hover{
                color: initial;
            }
        }
    }
    .addVal{
        border-left: solid 1px @lightWhite;
        padding-left: 5px;
        margin-left: 10px;
    }
}