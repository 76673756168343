#mother.mobile {
    .main-panel {
        width: 100%;
        left: 0;
        margin-top: 0;
        .content-panel{
            padding-top: 0;
        }
        .header {
            margin: 0 auto;
            width: 95%;
            background: transparent;
            box-shadow: none;
            .home{
                display: flex;
                margin-top: 10px;
                img{width: 130px;}
            }
            .top-part-header{
                display: flex;
                justify-content: space-between;
                height: 45px;
            }
            .control-block {
                width: fit-content;
                display: flex;
                justify-content: center;
                margin: 5px 0;
                #search {
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0;
                    flex: unset;
                    input {
                        border-radius: 3px;
                        font-size: 15px;
                        border: none;
                        color: white;
                        width: 150px;
                        height: 30px;
                        box-sizing: border-box;
                        padding-left: 40px;
                        &:focus {
                            outline: none;
                        }
                    }
                    .icon-search{
                        margin-right: -30px;
                        z-index: 2;
                        height: 16px;
                        svg {
                            width: 14px;
                            height: 14px;
                            path{
                                fill: @brightYellow;
                            }
                        }
                    }
                    .result {
                        width: 100%;
                        top: 50px;
                        display: flex;
                        flex-direction: column;
                        padding: 0;
                        background: #22263b;
                        .btn-wrap {
                            width: 95%;
                            display: flex;
                            justify-content: flex-end;
                            margin: 15px auto;
                            height: 30px;
                            button {
                                height: 30px;
                                padding: 5px 20px;
                                background: var(--panel-background);
                                margin: 0;
                                border-radius: 3px;
                                margin-right: 7px;
                            }
                        }
                        table {
                            width: 90%;
                            margin: 10px auto;
                            padding: 0;
                            overflow: hidden;
                            table-layout: fixed;
                            thead tr {
                                border: none;
                            }
                            tr, td {
                                cursor: pointer;
                            }
                            tbody {
                                tr {
                                    height: 25px;
                                    display: flex;
                                    td.pl {
                                        padding: 0;
                                        width: 10px;
                                        border-right: solid 1px var(--panel-text-shade);
                                        padding-right: 20px;
                                        svg {
                                            margin-top: 7px
                                        }
                                    }
                                }
                            }
                        }
                        .close-row {
                            th {
                                text-align: center;
                                padding-top: 10px;
                                span {
                                    color: @textColor;
                                    border: 2px solid @textColor;
                                    padding: 5px 10px 5px 10px;
                                    border-radius: 5px;
                                }
                            }
                        }
                        .head-row th {
                            padding-bottom: 5px;
                            border-bottom: solid 1px white;
                            &:first-of-type {
                                width: 20px;
                                padding-left: 0;
                                padding-right: 10px;
                                border-right: solid 1px var(--panel-text-shade);
                            }
                        }

                    }
                }
            }
            .time-block-wrap, .probe-block, .content-block{
                display: flex;
                min-height: 40px;
                align-items: center;
                background: var(--panel-background);
                border-radius: 3px;
                width: 100%;
                margin: 15px 0;
                padding: 5px 10px;
                box-shadow: var(--panel-shadow);
                box-sizing: border-box;
            }
            .content-block{
                text-align: center;
                color: white;
                .title-wrap, a{
                    width: 100%;
                    height: 100%;
                }
            }
            .probe-block{
                &.row ul{
                    align-items: center;
                    li.values{
                        max-width: 300px;
                        min-width: fit-content;
                        margin-bottom: 0;
                    }
                }
                .probes-ul {
                    flex-wrap: nowrap;
                    -webkit-overflow-scrolling: touch;
                    overflow-y: hidden;
                    overflow-x: auto;
                    height: 100%;
                    width: 100%;
                }
            }
            .time-block-wrap{
                justify-content: space-between;
                .fast-dial{
                    width: 100%;
                    justify-content: space-around;
                    padding: 0;
                }
                .utc{
                    padding: 0 5px;
                    margin: 0;
                }
            }
        }
        .graph-wrap{
            display: flex;
            flex-direction: column;
            .chart-container {
                width: 100%;
                padding: 0;
            }
            .graph-inner-wrap{
                width: 95%;
                margin: 0 auto;
                .panel{
                    margin: 0 auto;
                    .title-wrap{
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                        text-align: center;
                        color: white;
                    }
                    .title{
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 5px;
                        .ip-info{
                            color: var(--title);
                            margin-right: 10px;
                            font-size: 18px;
                            font-weight: bold;
                            letter-spacing: 0;
                            text-transform: uppercase;
                            &.no-title{
                                color: var(--graph-text);
                            }
                        }
                        .as-info{
                            font-size: @fontSize13;
                            color: var(--graph-text);
                        }
                    }
                    .switches{
                        display: flex;
                        width: 100%;
                        margin: 0 auto;
                        margin-bottom: 10px;
                        padding: 5px 10px;
                        box-sizing: border-box;
                        justify-content: space-around;
                        span,a{
                            line-height: 16px;
                            font-size: 17px
                        }
                        .switch{
                            margin: 0;
                            align-items: center;
                        }
                    }
                    .cntrl-btn{
                        text-decoration: none;
                        color: @brightYellow;
                    }
                }
            }
        }
        .footer {
            .footer-block.stat-block {
                justify-content: center;
            }
            .stat-block{
                height: auto;
                .span-info{
                    margin-bottom: 10px;
                }
            }
        }
        .traceroute-legend{
            width: 95%;
            margin-top: 20px;
            .title-head{
                justify-content: center;
                padding: 0;
                &__top{
                    .aspath{
                        flex-direction: column;
                        text-align: center;
                        width: 100%;
                        .title{
                            margin: 0;
                        }
                        ul{
                            display: flex;
                            justify-content: center;
                            margin: 5px 0;
                            li{
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
            .traceroute-detail{
                overflow: hidden;
                padding: 0;
                margin: 0;
            }
        }
        .trace-data-record{
            min-width: unset;
            .headline{
                display: block;
                width: 100%;
                text-align: center;
                color: white;
                margin-bottom: 15px;
            }
            clear: left;
            padding-top: 10px;
            .traces{
                overflow-x: hidden;
            }
            .trace-box{
                display: flex;
                margin: 0 0 15px 0;
                overflow: hidden;
                border-left: 1px solid @brightYellow;
                padding: 0 5px;
                width: 95%;
                position: relative;
                margin: 0 auto;
                margin-bottom: 15px;
                span, div{
                    pointer-events: none;
                }
                a{
                    display: flex;
                    justify-content: space-between;
                    overflow: hidden;
                    width: 100%;
                }
                .record{
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    width: calc(~"100% - 45px");
                    span{
                        color: gray;
                        font-size: 12px;
                    }
                    .pl-info{
                        margin-left: 75px;
                    }
                    .in-value{
                        margin-right: 5px;
                    }
                    .text-color{
                        color: @brightYellow;
                    }
                    margin-right: 10px;
                    &.description{
                        flex-grow: 0;
                        width: 55px;
                        span{
                            color: @brightYellow;
                        }
                    }
                }
                .host-record{
                    display: inline-block;
                    span{
                        color: gray;
                        font-size: 12px;
                        word-break: break-all;
                    }
                }
                .pl-box{
                    width: 45px;
                    height: 35px;
                    border: solid;
                    border-radius: 10px;
                    text-align: center;
                    span{
                        margin: -2px 0 2px 0;
                        width: 100%;
                        display: block;
                    }
                    .small{
                        margin: 0;
                        font-size: 10px;
                        color: var(--panel-text)
                    }
                    .val{color: var(--panel-text)}
                }
            }
        }
    }
}