.settings{
    .side-panel{
        ul.options{
            padding-left: 35px;
            margin-top: 55px;
            li {
                display: flex;
                margin-bottom: 30px;
                i.fa{
                    padding: 0;
                    margin: 0 15px 0 0;
                }
                a{
                    font-size: 12px;
                    letter-spacing: 0.46px;
                    color: @sideText;
                }
                &.active{
                    a{
                        color: @sideTextHover;
                    }
                }
                &:hover{
                    a{
                        color: @sideTextHover;
                    }
                }
            }
        }
    }
}