.selectcon.fa {
    background-image: none;
    top: 40%;
    width: auto;
}

input.datepicker-trigger {
    display: inline-block;
    float: left;
    font-size: @fontSizeStandard;
    background: transparent;
    height: 1px;
    color: rgba(255, 255, 255, 0.7);
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    text-align:right;
    margin-right: 20px;
    width: 0px;
}

.datepicker-wrapper{
    display: block;
    line-height: 19px;
    margin-top: 7px;
}

body div.daterangepicker {
    display: none;
    border: none;
    background: @contextMenu;
    color: @white;
    .rounded-corners(4px);
    margin-right: 5px;
    min-width: 520px;
    max-width: 580px;
    margin-right: 100px;
    &::before, &::after {
        display: none;
    }
    .daterangepicker_input {
        display: none;
    }
    .applyBtn.btn {
        .rounded-corners(20px);
        font-size: @fontSizeStandard;
        background: #7B8663;
        height: 30px;
        width: 70px;
        color:white;
        bottom: 10px;
        &:hover {
            background: #ADC65C;
            color: white;
            cursor: pointer;
        }
    }
    .drp-buttons{
        text-align: center;
        border: none;
    }
    .ranges {
        float: none !important;
        width: 520px;
        //border-bottom: 1px solid @datepickerGrey;
        padding: 0 5px 10px 5px;
        font-size: @fontSizeStandard;
        ul {
            width: 520px;
            height: 60px;
        }
        li {
            color: @white;
            font-size: @fontSizeStandard;
            background: transparent;
            width: 120px;
            border: none;
            display: inline;
            white-space: pre;
            margin: 0;
            float: left;
            text-align: justify;
            &[data-range-key='18 hours'], &[data-range-key='2 months'] {
                width: 62px;
            }
            &[data-range-key='Custom Range']{
                display:none;
            }
            &:hover, &.active {
                color: @datepickerActive;
            }
        }
    }
    .calendar-table {
        background: #5A5E6F;
        border: none;
        width: 260px;
    }
    table {
        border-collapse: collapse;
        width: 200px;
        height: 225px;
        font-size: 13px;
    }
    .calendar.right {
        margin-right: 0;
    }
    .calendar-left {
        clear: both !important;
    }
    .calendar.left .fa-calendar::before {
        content: 'From:';
    }
    .calendar.right .fa-calendar::before {
        content: 'To:';
    }
    //th.prev, th.next{
    //    background: #7B8663;
    //    display: block;
    //    min-width: 20px;
    //    width: 20px;
    //    height: 20px;
    //    padding: 0;
    //    margin: 0;
    //    border-radius: 20px;
    //    border: none;
    //    line-height: 20px;
    //    text-align: center;
    //    i{
    //        margin-right: 3px;
    //        margin-top: 4px;
    //    }
    //    &:hover{
    //        background: #ADC65C;
    //    }
    //}
    th.next i {
        margin-right: -1px;
    }
    th { //dni v tyzdni
        color: var(--panel-text-shade);
        font-weight: normal;
        min-width: 30px;
        .available:hover{
            border-radius: 20px;
        }
    }
    th.month { // nadpis mesic
        padding: 0;
        color: @white;
        text-transform: uppercase;
    }
    tbody tr:first-of-type { //border top prveho riadku
        //border-top: 1px double @datepickerGrey;
    }
    td {
        //border: 1px solid @datepickerGrey;
        border-radius: 0;
        height: 20px;
        border-radius: 25px;
        font-weight: 200 !important;
        background: @contextMenu;
        &.active {
            background: #7B8663;
            color: white;
        }
        &:hover, &.available:hover {
            background: #7B8663;
        }
        &.in-range {
            background: #7B8663;
            color: white;
            //color: @modalBackground;
        }
        &.off, &.off.active, &.off.in-range,
        &.off.off.disabled, &.off:hover {
            color: var(--panel-text-shade);
            background: @contextMenu;
            //border: 1px solid @datepickerGrey;
        }
        &.off.disabled {
            text-decoration: none;
            cursor: pointer;
        }
        &.off.available, &.off.disabled {
            color: var(--panel-text-shade);
        }
    }
    td.start-date{
        background: #ADC65C;
        border-radius: 20px 0 0 20px;
        &:before{
            //content: "19";
            background: #adc65c;
            border-radius: 20px;
            width: 30px;
            height: 25px;
            position: absolute;
            display: inline-block;
            margin-top: -5px;
            margin-left: -10px;
            border-radius: 30px;
            line-height: 25px;
            color: white;
            font-size: 15px;
        }
    }
    td.end-date{
        background: #ADC65C;
        border-radius: 0 20px 20px 0;
        &:before{
            //content: "";
            background: #ADC65C;
            border-radius: 20px;
            width: 23px;
            height: 40px;
            position: absolute;
            display: inline-block;
            margin-top: -12px;
        }
    }
    td.start-date.end-date{
        background: #ADC65C;
        border-radius: 20px;
    }

    thead tr:last-child th {
        color: rgba(255,255,255,0.3);
        text-transform: uppercase;
        font-size: @fontSize13;
    }
}
@media (min-width: 564px){
    .daterangepicker.ltr .calendar.left .calendar-table, .daterangepicker.ltr .left .daterangepicker_input{
        padding-right: 0px !important; //overwrite plugin default padding value
    }
}