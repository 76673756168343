.probe-block {
    display: flex;
    min-height: 30px;
    overflow: hidden;
    color: var(--header-text);
    margin: 10px 15px;
    &.row {
        ul{
            flex-direction: row;
            li.values{
                max-width: 155px;
                min-width: 100px;
                margin-right: 5px;
            }
        }
    }
    &.column {
        ul {
            flex-direction: column;
            li {width: 155px;}
        }
    }
    svg:not(.chevron-svg) {
        margin-right: 5px;
        height: 11px;
        width: 11px;
        padding-top: 3px;
        padding-right: 5px;
        margin-top: 3px;
        overflow: initial;
    }
    ul {
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        flex-grow: 1;
        li {
            text-transform: uppercase;
            font-size: @fontSizeStandard;
            overflow: hidden;
            white-space: nowrap;
            border-radius: 20px;
            height: 25px;
            display: flex;
            margin-bottom: 5px;
            padding: 0 15px;
            box-sizing: border-box;
            border: solid 1px transparent;
            .probeAction();
            span,svg,circle,i{
                pointer-events: none;
            }
            &:hover{
                cursor: pointer;
                .probe-wrap.hover-text span {
                    transition: all 5s ease;
                    transform: translateX(calc(~'-90%'));
                }
            }
            .probe-wrap{
                margin-top: 3px;
                margin-right: 5px;
                display: inline-block;
                overflow: hidden;
                height: 20px;
                span:not(.first-letter){
                    display: inline-block;
                }
            }
            span {
                font-size: @fontSize13;
                color: var(--header-text);
                font-weight: 200;
                letter-spacing: 1px;
                text-align: left;
                padding: 0;
                .first-letter {
                    color: var(--title);
                    padding-right: 1px;
                    font-weight: bold;
                    opacity: 1;
                }
            }
            &.showall{
                min-width: 50px;
                text-align: center;
                border: solid 1px rgba(255,255,255,0.1);
                i{
                    width: 100%;
                    margin-top: 4px;
                }
                &:hover{border: 1px solid #accc32;}
            }
            &.winner{
                svg circle{
                    fill: var(--probe-trophy) !important;
                }
                .fa-trophy{
                    color: gold !important;
                    font-size: 13px;
                    padding: 4px 10px 0 0;
                }
            }
        }
    }
    .side-action-button{
        margin-top: 3px;
    }
    .probes-select {
        background: @sidePanelColor;
        width: 200px;
        height: 40px;
    }
    &.closed{
        height: 30px;
    }
}