.auto-group-settings{
    .range-slider{
        display: flex;
        width: 200px;
        margin-top: 13px;
        span{
            &:hover{
                cursor: pointer;
            }
        }
    }
}
.auto-group-box{
    display: flex;
    & > div {
        display: flex;
        flex-direction: column;
    }
    .right-space{
        margin-right: 50px;
    }
}