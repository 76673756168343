.traceRoute-wrap{
    .back{
        font-size: 18px;
        color: @darkGrey;
        i{
            margin-right: 20px;
        }
        &:hover{
            color: white;
        }
    }
    .trace-header{
        position: relative;
        margin: 0 auto;
        display: block;
        width: 80%;
        color: var(--title);
        h1{
            font-size: @fontSizeH1;
            font-weight: 300;
        }
        h2{
            font-size: @fontSizeH2;
            font-weight: 300;
        }
    }
}
.trace-table-top{
    display: flex;
    justify-content: space-between;
    padding: 0 15px 10px 0;
    .fa-copy{
        color: var(--link);
    }
}

.traceroute-options{
    width: 100%;
    display: flex;
    .inp-check{
        margin-left: 30px;
        margin-top: 2px;
    }
}