/* The switch - the box around the slider */
.switch-slider {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 20px;
}

/* Hide default HTML checkbox */
.switch input {display:none;}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--slider-background);
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 0px;
    bottom: 0px;
    background-color: var(--slider-dot);
    -webkit-transition: .4s;
    transition: .4s;
    bottom: 2px;
    left: 2px;
}

//input:checked + .slider {
//    background-color: @brightYellow;
//}

input:focus + .slider {
    box-shadow: 0 0 1px var(--slider-dot);
}

input:checked + .slider:before {
    transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 30px;
}

.slider.round:before {
    border-radius: 50%;
}