#mother{
    &>.login{
        margin: 50px auto;
        overflow: auto;
    }
    .login{
        width: 100%;
        display: flex;
        .content{
            display: flex;
            flex-direction: column;
            width: 600px;
            position: relative;
            margin: 0 auto;
            margin-top: 10px;
            .logo{
                margin-bottom: 20px;
                text-align: center;
                .home{
                    display: flex;
                    justify-content: center;
                }
                #text-logo{
                    display: inline-block;
                    margin-top: 15px;
                    margin-left: 10px;
                }
            }
            .login-prompt{
                text-align: center;
                color: transparent;
                margin-bottom: 20px;
                font-size: 20px;
            }
            .input-span{
                float: left;
                label{
                    display: block;
                    padding: 5px 0;
                    text-transform: uppercase;
                    color: #A2A5AA;
                    margin-bottom: 10px;
                }
                input{
                    margin-bottom: 20px;
                    width: 100%;
                    padding: 5px;
                    background: #1D2531;
                    border: none;
                    border-radius: 7px;
                    border: 1px solid #343B45;
                    color: #fff;
                    font-size: 20px;
                    font-weight: 300;
                    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
                    text-align: center;
                    &:focus{
                        border: 1px solid #478AD3;
                        outline: none;
                    }
                }
                display: block;
                margin: 0 auto;
                width: 70%;
            }
            .controls{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                border: none;
                color: white;
                padding: 0 20px;
                width: 70%;
                margin: 0 auto;
                margin-top: 20px;
                .link{
                    color: #478AD3;
                    padding-top: 15px;
                    &:hover{
                        color: white;
                        cursor: pointer;
                    }
                }
                button{
                    padding: 15px 40px 15px 40px;
                    border: none;
                    color: white;
                    border-radius: 7px;
                    /* IE10+ */
                    background: -ms-linear-gradient(left, #37B779 0%, #2AA385 100%);

                    /* Mozilla Firefox */
                    background: -moz-linear-gradient(left, #37B779 0%, #2AA385 100%);

                    /* Opera */
                    background: -o-linear-gradient(left, #37B779 0%, #2AA385 100%);

                    /* Webkit (Safari/Chrome 10) */
                    //background: -webkit-gradient(linear, left top, right top, color-stop(0, #37B779), color-stop(100, #2AA385));
                    background: -webkit-gradient(linear, left top, right top, color-stop(0, @midYellow), color-stop(100, @brightYellow));

                    /* Webkit (Chrome 11+) */
                    background: -webkit-linear-gradient(left, @midYellow 0%, @brightYellow 100%);

                    /* W3C Markup */
                    background: linear-gradient(to right, #37B779 0%, #2AA385 100%);
                    &:hover{
                        background: #37B779;
                        cursor: pointer;
                    }
                    &:focus{outline: none;}
                }
            }
            .sub-content{
                display: flex;
                flex-direction: column;
                &.reset{
                    text-align: center;
                    .input-span{
                        text-align: left;
                        width: 75%;
                    }
                    .title{
                        color: white;
                        font-size: 25px;
                        margin-bottom: 20px;
                    }
                }
                &.forgot{
                    text-align: center;
                    .title{
                        color: white;
                        font-size: 25px;
                        margin-bottom: 20px;
                    }
                    .desc{
                        color: #969AA0;
                        font-size: 17px;
                        float: left;
                        display: block;
                        margin: 0 auto;
                        margin-bottom: 20px;
                        width: 75%;
                    }
                    .input-span{
                        text-align: left;
                        width: 73%;
                    }
                    .butts{
                        margin: 0 auto;
                    }
                }
            }
        }
        .hot-fix{
            border: solid #2A53AC;
            border-radius: 10px;
            position: absolute;
            top: 150px;
            padding: 60px;
            font-size: 20px;
            color: white;
            background: @mainBackground;
            opacity: 0.8;
            .controls{
                justify-content: center;
            }
        }
    }
    &.mobile .login{

    }
}