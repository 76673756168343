.as-info{
    &__panel{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        flex: 1 0 auto;
    }
    &__load{
        background: transparent;
        color: white;
        .sk-fading-circle{
            margin: 25px auto;
        }
    }
    &__block {
        width: 95%;
        border-radius: 3px;
        margin: 0 auto;
        box-sizing: border-box;
        margin-bottom: 20px;
        padding: 10px 15px;
        flex: 0 1 auto;
        h3 {
            text-align: left;
            color: white;
            margin: 0;
        }

        &--row {
            display: flex;
            flex-direction: row;
            width: 95%;
            justify-content: space-between;
            flex-wrap: wrap;
            flex: 0 0 auto;
            @media (max-width: 1000px) {
                justify-content: center;
            }
        }
    }
    @media(max-width: 500px){
        &__block {
            padding: 5px;
        }
    }
    &__border{
        border: solid 1px var(--panel-background);
        border-radius: 3px;
        display: flex;
        flex-direction: column;
        width: 95%;
        margin-bottom: 20px;
        flex: 0 0 auto;
        padding: 20px 0;
        h3{
            text-align: left;
            color: white;
            margin: 0;
        }
        &--half{
            width: 40%;
            min-width: 350px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @media(max-width: 500px){
                width: 100%;
                min-width: auto;
                height: 100%;
            }
            &-img{
                border-radius: 3px;
                border: none;
                a{
                    border: solid 1px transparent;
                    border-radius: 3px;
                    img{
                        height: 200px;
                        border-radius: 3px;
                    }
                }
                &:hover{
                    a{
                        position: relative;
                        &::after{
                            content: 'show graph';
                            position: absolute;
                            color: white;
                            left: 0;
                            top: 0;
                            background: rgba(0,0,0,0.5);
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
            }
        }
        .search{
            display: flex;
            width: 90%;
            margin: 10px auto;
            justify-content: flex-start;
            input{
                background: var(--panel-background);
                width: 200px;
            }
            a{
                display: flex;
                background: var(--panel-background);
                margin-left: 20px;
                height: 30px;
                justify-content: center;
                align-items: center;
                padding: 0 15px;
                border-radius: 3px;
                color: white;
                &:hover{
                    color: @brightYellow;
                }
            }
            @media(max-width: 500px){
                flex: 0 0 70px;
                flex-direction: column;
                a{
                    margin: 0;
                    margin-top: 10px;
                }
            }
        }
        .radar-link{
            width: 90%;
            margin: 0 auto;
            text-align: center;
            color: rgba(255,255,255,0.7);
            &:hover{
                color: white;
                cursor: pointer;
            }
        }
    }
}

.AS-neighbors{
    display: flex;
    flex-direction: column;
    width: 95%;
    height: 100%;
    &__top{
        display: flex;
        .count{
            color: rgba(255,255,255,0.7);
            margin: 0 5px;
        }
        a{
            color: white;
            margin: 0 10px;
        }
    }
    &__list{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 10px 0;
        height: 100%;
    }
    &__item{
        display: flex;
        padding: 5px 10px;
        border-radius: 3px;
        margin-right: 10px;
        margin-bottom: 5px;
        background: transparent;
        border: solid 1px var(--panel-background);
        color: var(--panel-text);
        width: 80px;
        justify-content: center;
        height: 20px;
        &--isin{
            background: var(--panel-background);
        }
        &--long{
            width: auto;
        }
        &-name{
            color: hsla(0,0%,100%,.5);
            padding-left: 10px;
        }
        &:hover{
            color: @brightYellow;
            border-color: @brightYellow;
        }
    }
    .no-result{
        color: rgba(255,255,255,0.7);
    }
    @media(max-width: 500px){
        width: 100%;
        &__item{
            padding: 5px 2px;
            margin-right: 5px;
            &-name{
                font-size: 12px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            &--long{
                width: 90%;
                justify-content: left;
            }
        }
    }
}
.stats{
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 15px auto;
    &__list{
        margin: 5px 3px;
    }
    &__item{
        display: flex;
        color: white;
        padding: 2px 0;
        span{
            color: rgba(255,255,255,0.7);
            padding: 0 15px;
        }
        @media(max-width: 500px){
            max-width: 100%;
            &--desc{
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
}