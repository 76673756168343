.select-probe-wrap {
    display: inline-block;
}
.header {
    .side-action-button{
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: transparent;
        border: solid 1px var(--header-matrix-text);
        box-sizing: border-box;
        display: flex;
        .chevron{
            display: block;
            color: var(--header-matrix-text);
            width: 16px;
            height: 16px;
            border-radius: 50%;
            text-align: center;
            svg {
                width: 8px;
                height: 15px;
                padding-top: 1px;
                path{
                    fill: var(--title);
                }
            }
            &.up{
                transform: rotate(180deg);
                svg {
                    padding-top: 0px;
                }
            }
        }
        &:hover{
            background: var(--header-hover);
            border-color: var(--header-hover);
            .lightIcon(2px, var(--header-hover));
        }
    }
    .control-head{
        margin-left: 5px;
    }
}