.control-panel-wrap{
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    min-height: 65px;
    @media(max-width: 591px){
        min-height: 130px;
    }
    .control-panel, .settings-box{
        border-radius: 3px;
        box-shadow: var(--panel-shadow);
        box-sizing: border-box;
        background: var(--panel-background);
        padding: 10px 15px;
        margin-bottom: 20px;
        min-height: 45px;
    }
    .control-panel{
        display: flex;
        overflow: hidden;
    }

    .title-wrap {
        display: flex;
        height: 25px;
        line-height: 25px;
        overflow: hidden;
        width: 100%;
        a{
            width: 100%;
            text-align: center;
        }
        a:nth-child(1){
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .ip-info {
            color: var(--title);
            margin-right: 20px;
            margin-left: 20px;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0;
            text-transform: uppercase;
            &.no-title {
                color: var(--graph-text);
            }
        }
        &__move{
            color: rgba(255,255,255,0.2);
            &:hover{
                color: var(--link);
            }
        }
        .as-info {
            font-size: @fontSize13;
            color: var(--graph-text);
            margin-right: 10px;
            width: 100%;
            text-align: center;
            .val{
                margin-left: 5px;
            }
        }
    }
}

